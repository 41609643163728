@keyframes slideInFromTop {
    0% {
      transform: translateY(-100%) scale(0.9);
      opacity: 0;
    }
    50% {
      transform: translateY(20%) scale(1.05);
      opacity: 0.7;
    }
    100% {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }
  
  .welcome-animation {
    animation: slideInFromTop 1s ease-out;
  }