@keyframes dots {
    0%, 20% {
      content: ".";
    }
    40% {
      content: "..";
    }
    60% {
      content: "...";
    }
    80%, 100% {
      content: "";
    }
  }
  
  .dots::after {
    content: "";
    animation: dots 1s steps(4, end) infinite;
  }